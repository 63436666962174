<template>
  <menubar />
  <main-container>
    <template v-slot:header>
      <return-container />
    </template>

    <div id="a-add-test-container">
      <h3>Ingresa los datos para crear la prueba</h3>
      <div class="a-test-form">
        <div class="a-column-left">
          <div class="p-field">
            <label for="a-test-name">Nombre de la prueba</label>
            <InputText
              type="text"
              id="a-test-name"
              placeholder="Lorem ipsum"
              v-model="form.name"
            />
          </div>
          <div class="p-field">
            <label for="a-test-name">Descripción de la prueba</label>
            <Textarea
              v-model="form.description"
              :autoResize="false"
              rows="5"
              cols="25"
              placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent luctus, dui quis tempor blandit, purus lorem lacinia libero, non fermentum orci magna eget urna."
            />
          </div>
          <div class="p-field">
            <label for="a-test-name">No. de preguntas</label>
            <InputText
              type="number"
              placeholder="00"
              v-model="form.questions"
            />
          </div>
          <div class="p-field">
            <label for="a-test-name">No. de intentos</label>
            <InputText type="number" placeholder="00" v-model="form.attempts" />
          </div>
        </div>
        <!-- COLUMN RIGHT -->
        <div class="a-column-right">
          <div class="p-field">
            <label for="a-test-name">Tiempo máx. (min)</label>
            <InputText type="number" placeholder="00" v-model="form.time" />
          </div>
          <div class="p-field">
            <label for="a-test-name">Video</label>
            <FileUpload
              name="demo[]"
              mode="basic"
              @select="onVideoUpload"
              :chooseLabel="'Seleccionar video'"
              accept="video/*"
            />
          </div>
          <div class="p-field">
            <label for="a-test-name">Descripción del video</label>
            <Textarea
              v-model="form.descriptionVideo"
              :autoResize="false"
              rows="5"
              cols="25"
              placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent luctus, dui quis tempor blandit, purus lorem lacinia libero, non fermentum orci magna eget urna."
            />
          </div>
          <div class="p-field">
            <label for="a-test-name">Icono de la prueba</label>
            <div class="a-icon-selector" @click="settings.addIcon = true">
              <span v-if="form.icon">{{ form.icon }}</span>
              <span v-else>Selecciona el icono</span>
              <i :class="form.icon" v-if="form.icon"></i>
            </div>
          </div>
        </div>
      </div>
      <div id="a-form-buttons">
        <primary-button
          :title="'Siguiente'"
          :isValid="isValid"
          :isLoading="isLoading"
          @onClick="newTest"
        />
        <Button label="Regresar" class="p-button-danger" />
      </div>
    </div>
  </main-container>

  <Dialog
    header="Seleccionar ícono"
    v-model:visible="settings.addIcon"
    v-if="settings.addIcon"
    :modal="true"
    :breakpoints="{ '960px': '90vw' }"
    :style="{ width: '90vw' }"
  >
    <Vue3IconPicker @iconSelected="iconSelected" />
  </Dialog>
</template>

<script>
import Menubar from "../../../components/navigation/Top.vue";
import MainContainer from "../../../components/secure/MainContainer.vue";
import ReturnContainer from "@/components/navigation/ReturnContainer.vue";
import Vue3IconPicker from "@/components/iconPicker/iconPicker.vue";
import PrimaryButton from "@/components/widgets/PrimaryButton.vue";
import { mapMutations } from "vuex";

export default {
  components: {
    Menubar,
    MainContainer,
    ReturnContainer,
    Vue3IconPicker,
    PrimaryButton,
  },
  data() {
    return {
      isLoading: false,
      form: {
        name: "",
        description: "",
        attempts: "",
        questions: "",
        time: "",
        icon: "",
        video: "",
        descriptionVideo: "",
        active: true,
      },
      settings: {
        addIcon: false,
      },
    };
  },
  computed: {
    isValid() {
      var isValid = true;
      for (const key in this.form) {
        if (this.form[key] == "") {
          isValid = false;
        }
      }
      return isValid;
    },
  },
  methods: {
    ...mapMutations("test", ["setFile"]),
    iconSelected(value) {
      this.settings.addIcon = false;
      this.form.icon = value;
    },
    onVideoUpload(event) {
      this.form.video = event.files[0];
    },
    newTest() {
      if (this.isValid) {
        this.isLoading = true;
        this.setFile(this.form.video);
        delete this.form.video;

        this.$store
          .dispatch("test/SAVE_TEST", this.form)
          .then(() => {
            this.$router.push({ name: "AddQuestions" });
          })
          .catch((error) => {
            if (error == "token") {
              this.newTest();
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: error,
                life: 3000,
              });
            }
          });
      }
    },
  },
};
</script>

<style>
html,
body,
#app {
  background: white;
}

.a-main-content {
  padding: 0;
}

#a-add-test-container .p-inputtext::placeholder,
#a-add-test-container textarea::placeholder {
  text-align: left;
  font-family: "Montserrat", sans-serif !important;
}
</style>

<style scoped>
#a-return-container {
  background: var(--color-3);
  height: 60px;
  width: 100%;
  margin: 0 0 20px;
  border-radius: 18px;
}
#a-add-test-container {
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

#a-add-test-container h3 {
  font-family: Quicksand;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.44px;
  text-align: center;
  color: var(--color-2);
}
label {
  margin-bottom: 5px;
  margin-top: 15px;
  display: inline-block;
}

.a-test-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
}
.a-test-form .a-column-left,
.a-test-form .a-column-right {
  justify-self: center;
}
.a-test-form * {
  width: 100%;
}
</style>
