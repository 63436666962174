<template>
  <div id="a-sidebar">
    <button
      :class="activeRoute == 'Registers' ? 'a-active a-icon-btn' : 'a-icon-btn'"
      @click="$router.push('/administrador/registros')"
    >
      <div>
        <img :src="require('@/assets/icons/registers.svg')" />
      </div>
    </button>
    <button
      :class="activeRoute == 'Tests' ? 'a-active a-icon-btn' : 'a-icon-btn'"
      @click="$router.push('/administrador/pruebas')"
    >
      <div>
        <img :src="require('@/assets/icons/tests.svg')" />
      </div>
    </button>
    <button
      :class="activeRoute == 'Users' ? 'a-active a-icon-btn' : 'a-icon-btn'"
      @click="$router.push('/administrador/usuarios')"
    >
      <div>
        <img :src="require('@/assets/icons/users.svg')" />
      </div>
    </button>
  </div>
  <Toast />
</template>

<script>
export default {
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    activeRoute() {
      return this.$route.name;
    },
    usertype() {
      if (
        this.$store.state.user != null &&
        this.$store.state.user.userType != null
      ) {
        return this.$store.state.user.userType;
      } else {
        return "Editor";
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
};
</script>

<style scoped>
#a-sidebar {
  width: 86px;
  height: 100%;
  background: var(--text-color);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

#a-sidebar .a-icon-btn {
  margin: 0 0 20px 0;
  padding: 0;
}
#a-sidebar .a-icon-btn div {
  background: white;
}
#a-sidebar .a-icon-btn img {
  height: 24px;
  width: 24px;
}
.a-active {
  width: 100%;
  position: relative;
}

.a-active-rounded-border {
  position: absolute;
  width: 4px;
  right: -2px;
  margin: 0;
}

.a-active div {
  background: var(--color-2) !important;
}

.a-active img {
  filter: brightness(0) invert(1);
}
</style>
