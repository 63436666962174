<template>
  <div class="aim-modal--content">
    <div class="aim-modal--body">
      <div class="aim-modal--sidebar">
        <div class="aim-modal--sidebar-tabs">
          <div
            class="aim-modal--sidebar-tab-item"
            data-library-id="all"
            v-for="tab in tabs"
            :key="tab.id"
            :class="{ 'aesthetic-active': isActiveTab(tab.id) }"
            @click="setActiveTab(tab)"
          >
            <span>{{ tab.title }}</span>
          </div>
        </div>
        <div class="aim-sidebar-preview">
          <div class="aim-icon-item">
            <i :class="activeGlyph"></i>
            <div class="aim-icon-item-name">
              {{ activeGlyph }}
            </div>
          </div>
        </div>
      </div>
      <div class="aim-modal--icon-preview-wrap">
        <InputText
          type="text"
          id="a-test-name"
          placeholder="Buscar"
          v-model="filterText"
        />

        <div class="aim-modal--icon-preview-inner">
          <div class="aim-modal--icon-preview">
            <div
              class="aim-icon-item"
              v-for="glyph in glyphs"
              :key="glyph"
              :class="{ 'aesthetic-selected': isActiveGlyph(glyph) }"
              @click="setActiveGlyph(glyph)"
            >
              <i :class="glyph"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="aim-modal--footer">
      <primary-button :title="'Añadir'" @click="insert" :isValid="true" />
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import fontLibrary from "./data/fonts";
import PrimaryButton from "../widgets/PrimaryButton.vue";

export default {
  components: { PrimaryButton },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: "fas fa-circle",
    },
  },
  setup(props, context) {
    const filterText = ref("");
    const activeGlyph = ref(props.modelValue);
    const tabs = [
      {
        id: "all",
        title: "Todos",
        link: "all",
      },
      {
        id: "far",
        title: "Regular",
        link: fontLibrary.fontAwesome.variants.regular,
      },
      {
        id: "fas",
        title: "Sólido",
        link: fontLibrary.fontAwesome.variants.solid,
      },
      {
        id: "fab",
        title: "Marcas",
        link: fontLibrary.fontAwesome.variants.brands,
      },
    ];

    const activeTab = ref(tabs[0]);

    const allGlyphs = [].concat(
      tabs[1].link.icons,
      tabs[2].link.icons,
      tabs[3].link.icons
    );

    const glyphs = computed(() => {
      let _glyphs = [];
      if (activeTab.value.id !== "all") {
        _glyphs = activeTab.value.link.icons;
      } else {
        _glyphs = allGlyphs;
      }

      if (filterText.value != "") {
        const _filterText = filterText.value.toLowerCase();
        _glyphs = _glyphs.filter(
          (item) => item.substr(7, filterText.value.length) === _filterText
        );
      }
      return _glyphs;
    });

    const setActiveGlyph = (glyph) => {
      activeGlyph.value = glyph;
    };

    const isActiveGlyph = (glyph) => {
      return activeGlyph.value == glyph;
    };

    const isActiveTab = (tab) => {
      return tab == activeTab.value.id;
    };

    const setActiveTab = (tab) => {
      activeTab.value = tab;
    };

    const getGlyphName = (glyph) =>
      glyph.replace(/f.. fa-/g, "").replace("-", " ");

    const insert = () => {
      context.emit("iconSelected", activeGlyph.value);
    };

    return {
      fontLibrary,
      tabs,
      isActiveTab,
      setActiveTab,
      glyphs,
      activeGlyph,
      getGlyphName,
      filterText,
      setActiveGlyph,
      isActiveGlyph,
      insert,
    };
  },
};
</script>

<style scoped>
@import "./css/style.css";
</style>
