<template>
  <div id="a-return-container"></div>
</template>

<script>
export default {
  name: "ReturnContainer",
};
</script>

<style scoped>
#a-return-container {
  background: var(--color-3);
  height: 60px;
  width: 100%;
  margin: 0 0 20px;
  border-radius: 18px;
}
</style>
